<template>
  <div class="sceneAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="90px" size="small">
          <el-form-item label="场景名称:" prop="sceneName">
            <el-input type="text" v-model="inform.sceneName" placeholder="请输入场景名称"></el-input>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input type="textarea" :rows="5" v-model="inform.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item label="场景类型:" prop="sceneType">
            <el-radio-group v-model="inform.sceneType">
              <el-radio v-for="(item,index) in dicObj.typeData" :key="index" :label="item.dictValue">{{item.dictName}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="执行动作:" prop="actionType">
            <el-radio-group v-model="inform.actionType">
              <el-radio v-for="(item,index) in dicObj.actionData" :key="index" :label="item.dictValue">{{item.dictName}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="inform.sceneType=='1'">
          <el-form-item label="执行时间:" prop="weekScript">
            <el-checkbox-group v-model="inform.weekScript" size="small">
              <el-checkbox-button v-for="(item,index) in weekData" :key="index" :label="item.value">{{ item.name }}</el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="" prop="timeScript">
            <el-time-picker v-model="inform.timeScript" value-format="HH:mm" format="HH:mm" placeholder="执行时间"></el-time-picker>
          </el-form-item>
          <el-form-item label="是否启用:" prop="isEnable">
            <el-switch v-model="inform.isEnable" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
            <!-- <el-checkbox v-model="inform.isEnable" label="启用" :true-label="1" :false-label="0"/> -->
          </el-form-item>
          </template>
          <el-form-item label="执行设备:" prop="tableActive">
            <el-table class="tableCont" ref="multipleTable" row-key="deviceId" :data="tableData" stripe :height="300" style="width:100%" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55" fixed></el-table-column>
              <el-table-column prop="devName" label="设备名称" show-overflow-tooltip/>
              <el-table-column prop="statusName" label="在线状态"/>
              <el-table-column prop="homeName" label="场所"/>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        sceneName:'',//场景名称
        remark:'',//备注
        actionType:'',//执行动作
        tableActive:[],//表格选中
        isEnable:'0',//是否启用
        sceneType:'',//场景类型
        timeScript:'',//执行时间
        weekScript:[],//执行星期
      },
      tableData :[],

      rules :{
        sceneName: [
          { required: true, message: '请输入场景名称', trigger: 'blur' },
        ],
        sceneType: [
          { required: true, message: '请选择场景类型', trigger: 'change' },
        ],
        actionType: [
          { required: true, message: '请选择动作类型', trigger: 'change' },
        ],
        isEnable: [
          { required: true, message: '请选择是否启用', trigger: 'change' },
        ],
        tableActive: [
          { required: true, message: '请选择执行设备', trigger: 'change' },
        ],
        timeScript: [
          { required: true, message: '请选择执行时间', trigger: 'change' },
        ],
        weekScript: [
          { required: true, message: '请选择执行时间', trigger: 'change' },
        ],
      },
      weekData:[{
        value:'1',
        name:'周一'
      },{
        value:'2',
        name:'周二'
      },{
        value:'3',
        name:'周三'
      },{
        value:'4',
        name:'周四'
      },{
        value:'5',
        name:'周五'
      },{
        value:'6',
        name:'周六'
      },{
        value:'7',
        name:'周日'
      },]
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    async init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '800px'
      await vm.getDeviceList()
      vm.isDio = true
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '创建场景'
        }else if(type == 'edit'){
          vm.dioTit = '编辑场景'
          vm.getSceneDetail(item.id)
        }
      })
    },
    //获取场景详情
    async getSceneDetail(id){
      const res = await apiUrl.getSceneDetail(id)
      if(res?.code==200){
        let list = []
        for(let i in res.data.selectedDeviceList){
          for(let x in vm.tableData){
            if(vm.tableData[x].deviceId==res.data.selectedDeviceList[i].deviceId){
              list.push(vm.tableData[x])
              vm.$refs.multipleTable.toggleRowSelection(vm.tableData[x], true);
            }
          }
        }
        vm.inform = {
          id: res.data.id,
          sceneName:res.data.sceneName,//场景名称
          remark:res.data.remark,//备注
          actionType:`${res.data.actionType}`,//执行动作
          tableActive:list,//表格选中
          isEnable:`${res.data.isEnable}`,//是否启用
          sceneType:`${res.data.sceneType}`,//场景类型
          timeScript:res.data.timeScript,//执行时间
          weekScript:vm.findIdsByNames(vm.weekData,res.data.weekScript),//执行星期
        }
      }else{
        vm.$message.error(res.message)
      }
    },
    //通过字符串名字"周一,周二"返回value数组
    findIdsByNames(arr, namesStr) {  
      // 将字符串按逗号分割成数组  
      const names = namesStr.split(',');  
      // 存储符合条件的id  
      const ids = [];
      // 遍历数组  
      arr.forEach(item => {  
        // 如果当前项的名字在names数组中  
        if (names.includes(item.name.trim())) {  
          // 将id添加到ids数组中  
          ids.push(item.value);  
        }  
      });  
      // 返回id数组  
      return ids;  
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addScene()
          }else if(vm.dioType=='edit'){
            vm.updateScene()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.tableData = []
      vm.inform = {
        id:'',
        sceneName:'',//场景名称
        remark:'',//备注
        actionType:'',//执行动作
        tableActive:[],//表格选中
        isEnable:'0',//是否启用
        sceneType:'',//场景类型
        timeScript:'',//执行时间
        weekScript:[],//执行星期
      }
      vm.isDio = false
    },
    //表格多选
    handleSelectionChange(val) {
      vm.inform.tableActive = val;
    },
    //新增
    async addScene(){
      let arr = []
      for(let i in vm.inform.tableActive){
        let obj = {
          'deviceId':vm.inform.tableActive[i].deviceId,
          'deviceType':vm.inform.tableActive[i].devType,
        }
        arr.push(obj)
      }
      let dataObj = {
        'areaId':vm.$store.state.csType,//区域
        'homeId':vm.$store.state.homeId,//场所
        'sceneName':vm.inform.sceneName,//名称
        'remark':vm.inform.remark,//备注
        'actionType':vm.inform.actionType,//动作类型
        // 'isEnable':vm.inform.isEnable,//是否执行
        'sceneType':vm.inform.sceneType,//场景类型
        'sceneDeviceList':arr,//执行设备
      }
      if(vm.inform.sceneType=='1'){
        dataObj.isEnable = vm.inform.isEnable;//是否执行
        dataObj.weekScript = vm.arrayToWeekString(vm.inform.weekScript);//执行星期
        dataObj.timeScript = vm.inform.timeScript;//执行时间
      }else{
        dataObj.isEnable = '1';//是否执行
      }
      const res = await apiUrl.addScene(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateScene(){
      let arr = []
      for(let i in vm.inform.tableActive){
        let obj = {
          'deviceId':vm.inform.tableActive[i].deviceId,
          'deviceType':vm.inform.tableActive[i].devType,
        }
        arr.push(obj)
      }
      let dataObj = {
        'id':vm.inform.id,
        'areaId':vm.$store.state.csType,//区域
        'homeId':vm.$store.state.homeId,//场所
        'sceneName':vm.inform.sceneName,//名称
        'remark':vm.inform.remark,//备注
        'actionType':vm.inform.actionType,//动作类型
        // 'isEnable':vm.inform.isEnable,//是否执行
        'sceneType':vm.inform.sceneType,//场景类型
        'sceneDeviceList':arr,//执行设备
      }
      if(vm.inform.sceneType=='1'){
        dataObj.isEnable = vm.inform.isEnable;//是否执行
        dataObj.weekScript = vm.arrayToWeekString(vm.inform.weekScript);//执行星期
        dataObj.timeScript = vm.inform.timeScript;//执行时间
      }else{
        dataObj.isEnable = '1';//是否执行
      }
      const res = await apiUrl.updateScene(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //遍历星期为字符串0000000
    arrayToWeekString(arr) {
      // 初始化一个长度为7的字符串，全部填充为'0'
      let weekStr = '0000000';
      // 遍历数组
      arr.forEach(day => {
        // 将day转换为Number类型，减1得到正确的索引位置
        const index = Number(day) - 1;
        // 检查索引是否有效
        if (index >= 0 && index < 7) {
          // 将对应位置的字符改为'1'
          weekStr = weekStr.substring(0, index) + '1' + weekStr.substring(index + 1);
        }
      });
      return weekStr;
    },
    //获取设备列表
    async getDeviceList(){
      let dataObj = {
        'areaId':vm.$store.state.csType,//区域
        'homeId':vm.$store.state.homeId,//场所
        'pageNo': 1,
        'pageSize':99999,
        'devType':1,//断路器类型
      }
      const res = await apiUrl.devicePageList(dataObj)
      if(res?.code==200){
        vm.tableData = res.data.list
      }else{
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.sceneAddEdit{
  .contDio{
    max-height: 500px;
    overflow: auto;
    .tableCont{
      .el-checkbox{
        margin-right: 0;
      }
    }
  }
}
</style>