<template>
  <div class='safety-scene mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name"><div>检索条件</div></div>
      <el-form ref="searchForm" :model="keyWord" label-width="90px" inline @submit.native.prevent size="small">
        <el-form-item label="场景名称:" prop="name">
          <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入场景名称" clearable @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="场景类型:" prop="type">
          <el-select v-model="keyWord.type" placeholder="场景类型" class="w180" clearable @change="getList(1)">
            <el-option v-for="(item,index) in dicObj.typeData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场景动作:" prop="actionType">
          <el-select v-model="keyWord.actionType" placeholder="场景动作" class="w180" clearable @change="getList(1)">
            <el-option v-for="(item,index) in dicObj.actionData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:safety:scene:add')"><i class="el-icon-circle-plus-outline"></i>添加</el-button>
          <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:safety:scene:del')"><i class="el-icon-delete"></i>批量删除</el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed></el-table-column>
          <el-table-column prop="sceneName" label="场景名称"/>
          <el-table-column prop="remark" label="描述"/>
          <el-table-column :formatter="formatType" label="场景类型" width="100"/>
          <el-table-column :formatter="formatAction" label="场景动作" width="100"/>
          <el-table-column label="执行时间" align="center">
            <template slot-scope="scope">
              <p>{{scope.row.weekScript}}</p><span v-if="scope.row.timeScript!=''">{{scope.row.timeScript}}</span>
            </template>
          </el-table-column>
          <el-table-column label="启用" width="100">
            <template slot-scope="scope">
              <template v-if="scope.row.sceneType=='1'">
                <span v-if="scope.row.isEnable==1" class="success">是</span>
                <span v-else class="info">否</span>
              </template>
              <template v-else>-</template>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" class="primary" @click="openDio('edit',scope.row)" v-if="isAuth('safe:safety:scene:edit')"><i class="el-icon-edit"></i>修改</el-button>
              <el-button type="text" class="primary" v-if="scope.row.sceneType=='0'&&isAuth('safe:safety:scene:execute')" @click="openDio('execute',scope.row)"><i class="el-icon-s-promotion"></i>执行</el-button>
              <el-button type="text" class="error" @click="openDio('del',scope.row)" v-if="isAuth('safe:safety:scene:del')"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
          :current-page="curr"
          :page-size="limits"
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100,200]"
          :total="total"
          @size-change="sizeChange"
          @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <scene-add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="getList()"></scene-add-edit>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import sceneAddEdit from './scene-add-edit.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'safety-scene',
  data() {
    return {
      //筛选数据
      keyWord :{
        name:'',
        type:'',
        actionType:''
      },
      dicObj:{
        actionData:[],//场景动作
        typeData:[],//场景类型
      },
      //分页
      curr :1,//当前页
      limits :10,//每页容量
      total :0,//数据总数
      
      tableH :'',//表格高度
      tableActive:[],//表格选中
      tableData :[],

      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    sceneAddEdit,
    breadCrumb,
  },
  watch:{
    '$store.state.homeId':function(newVal){
      if(newVal!=='0'){
        vm.getList(1)
      }
    }
  },
  created(){
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
    vm.getCurrDics()
  },
  mounted(){
    if(vm.$store.state.homeId!=null) vm.getList()
    
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteScene(arr)
          })
          .catch(() => {})
      }else if(type == 'execute'){
        vm.$confirm('确定执行该场景？','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            vm.triggerScene(item.id)
          })
          .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'){
          vm.$refs.editDio.init(type,item);//新增、修改
        }
      }
    },
    //执行场景
    async triggerScene(id){
      const res = await apiUrl.triggerScene(id)
      if(res?.code==200){
        vm.$message.success('执行成功！')
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for(var i in vm.tableActive){
          arr.push(vm.tableActive[i].id)
        }
        vm.deleteScene(arr);//删除
      }).catch(() => {});
    },
    //删除
    async deleteScene(ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteScene(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //过滤场景类型
    formatType(row){
      let result = vm.dicObj.typeData.filter(item => item.dictValue == row.sceneType)
      return result[0]?.dictName||'-'
    },
    //过滤动作类型
    formatAction(row){
      let result = vm.dicObj.actionData.filter(item => item.dictValue == row.actionType)
      return result[0]?.dictName||'-'
    },
    //分页选择
    sizeChange (val){
      vm.limits = val
      vm.getList(vm.curr,val)
    },
    currChange (val){
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList (_curr,_limits){
      let dataObj = {
        "areaId": vm.$store.state.csType,
        "homeId": vm.keyWord.homeId,
        'pageNo': _curr?_curr:vm.curr,
        'pageSize': _limits?_limits:vm.limits,
        "sceneName": vm.keyWord.name,
        "sceneType": vm.keyWord.type,
        "actionType": vm.keyWord.actionType,
      }
      const res = await apiUrl.sceneListPage(dataObj)
      if(res?.code==200){
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr?_curr:vm.curr
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取数据字典
    async getCurrDics(){
      let dataObj = ['SCENE_ACTION_TYPE','SCENE_TYPE']
      const res = await apiUrl.getDicts(dataObj)
      if(res?.code==200){
        vm.dicObj.actionData = res.data?.SCENE_ACTION_TYPE||[];//场景动作
        vm.dicObj.typeData = res.data?.SCENE_TYPE||[];//场景类型
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.safety-scene{

}
</style>
